export const theme = {
  colors: {
    primary: '#654ea3',
    secondary: '#eaafc8',
    blue: '#13c2c2',
    lightgray: '#f8f8f8',
    gray: '#999',
    black: '#121212',
  },
};
